import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IUserEntity } from '../../interfaces/api/user-entity';

export enum UserSignalTypes {
  LOAD_USERS = '[User][Signal] Load Collection',
}
export enum UserMessageTypes {
  SET_COLLECTION = '[User][Message] Set Collection',
  ADD_ONE = '[User][Message] Add One',
  UPDATE_ONE = '[User][Message] Update One',
  UPSERT_ONE = '[User][Message] Upsert One',
  DELETE_ONE = '[User][Message] Delete One',
  ADD_MULTIPLE = '[User][Message] Add All',
  DELETE_MULTIPLE = '[User][Message] Delete Many',
  UPSERT_MULTIPLE = '[User][Message] Upsert Many',
  UPDATE_MULTIPLE = '[User][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = UserMessageTypes.SET_COLLECTION;
  constructor(public payload: { users: IUserEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = UserMessageTypes.ADD_ONE;

  constructor(public payload: { user: IUserEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = UserMessageTypes.UPSERT_ONE;

  constructor(public payload: { user: IUserEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = UserMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { users: IUserEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = UserMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { users: IUserEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = UserMessageTypes.UPDATE_ONE;

  constructor(public payload: { user: Update<IUserEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = UserMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { users: Update<IUserEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = UserMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = UserMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type UserMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
