import { messageableFactory as actionableFactory, alertStateAdapter } from './form.adapter';
import { IHospitalOfficerFormState } from '../interfaces/hospital-officer-form-state';

// initialization of mixin factory
export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeHospitalOfficerFormMessage extends actionableFactory.create<
  'Initialize Hospital Officer Form',
  { hospitalOfficerFormState: IHospitalOfficerFormState }
>('Initialize Hospital Officer Form') {}

export type HospitalOfficerFormMessages =
  | InitializeHospitalOfficerFormMessage
  | ResetErrorMessage
  | AlertErrorMessage;
