import { UserMessageTypes as MessageTypes, UserMessages } from './user.messages';
import { IUserEntityState } from './interfaces';
import { adapter, initialState } from './user.adapter';

export * from './interfaces';

export function reducer(state = initialState, action: UserMessages): IUserEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.user, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.user, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.users, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.users, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.users, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.user, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.users, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
