import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { get, isNumber, isString, uniq } from 'lodash-es';
import { box } from 'ngrx-forms';
import { combineLatest, concat, EMPTY, from, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, share } from 'rxjs/operators';

import { PaginatedStateService, Query } from '@locumsnest/core/src';
import { toSessionUser } from '@locumsnest/core/src/lib/ngrx/operators';

import { HealthCheckService } from '../../health-check/+state/health-check.service';
import { HospitalOfficerSiteService } from '../../hospital-officer-site/+state/hospital-officer-site.service';
import {
  IHospitalOfficerConfiguration,
  IHospitalOfficerConfigurationFormState,
} from '../../hospital-officer/+state/interfaces';
import { selectAssignedHospitalControlContactOfficer } from '../../hospital/+state/hospital.selectors';
import {
  IExtendedHospitalOfficerEntity,
  IHospitalOfficerEntity,
  IHospitalOfficerPersonalDetailsEntity,
  IHospitalOfficerWithUser,
} from '../../interfaces/api/hospital-officer-entity';
import { IUserEntity } from '../../interfaces/api/user-entity';
import { PermissionService } from '../../permission/+state/permission.service';
import { SetSelectUnassignedMessage } from '../../preferred-ward/+state/preferred-ward.messages';
import * as preferredWardTemp from '../../preferred-ward/+state/temp/preferred-ward-temp.messages';
import { UserService } from '../../user/+state/user.service';
import { InitializeHospitalOfficerFormMessage } from './form/form.messages';
import { selectHospitalOfficerFormStateFromEntity } from './form/form.selectors';
import { loadingAdapter } from './hospital-officer.adapter';
import {
  HospitalOfficerPaginationMessages,
  HospitalOfficerMessageTypes as MessageTypes,
  ResetHospitalOfficerPaginationMessage,
  SetAssignedMessage,
  UpsertHospitalOfficerPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './hospital-officer.messages';
import { HospitalOfficerPersistenceService } from './hospital-officer.persistence.service';
import {
  getHospitalOfficerPersonalDetails,
  hospitalOfficerPaginationSelectors,
  loadingStateSelectors,
  selectAllHospitalOfficers,
  selectAssignedHospitalOfficer,
  selectAssignedHospitalOfficerPreferredSpecialty,
  selectHospitalOfficerEntityState,
  selectHospitalOfficerPersonalDetails,
  selectHospitalOfficerUser,
} from './hospital-officer.selectors';

@Injectable({
  providedIn: 'root',
})
export class HospitalOfficerService extends PaginatedStateService<
  IHospitalOfficerEntity,
  UpsertHospitalOfficerPageMessage,
  ResetHospitalOfficerPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: HospitalOfficerPersistenceService,
    protected userService: UserService,
    protected permissionService: PermissionService,
    protected hospitalOfficerSiteService: HospitalOfficerSiteService,
    protected healthCheckService: HealthCheckService,
  ) {
    super();
  }

  private exportNamespace = 'exportOfficerData';

  get paginationMessages() {
    return HospitalOfficerPaginationMessages;
  }

  get paginationSelectors() {
    return hospitalOfficerPaginationSelectors;
  }

  get entityStateSelector() {
    return selectHospitalOfficerEntityState;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  bulkCreate(data: IHospitalOfficerConfiguration[]) {
    return this.persistenceService.bulkCreate(data);
  }

  importData(data: IHospitalOfficerConfiguration[]) {
    return this.persistenceService.importData(data);
  }

  loadExport() {
    return this.loadPage(
      this.exportNamespace,
      { controllerResource: 'exportOfficerData', skipSerializer: true },
      1,
      {},
      true,
      false,
      (data) => ({
        data: data.results.map((entity) => ({
          ...entity,
          user: entity.user.id,
        })),
        actions: [this.processExportData(data.results)],
      }),
    );
  }

  getAll() {
    return this.store.pipe(select(selectAllHospitalOfficers));
  }

  getOneUserId(officerId) {
    return this.getOne(officerId).pipe(
      map(({ user }) => user),
      distinctUntilChanged(),
    );
  }

  getAllExtendedHospitalOfficers(): Observable<IExtendedHospitalOfficerEntity[]> {
    return this.getConsecutivePageEntities(this.exportNamespace).pipe(
      mergeMap((officers) =>
        this.userService.getEntities().pipe(
          map((userEntities) =>
            officers.map((officer) => {
              const user = userEntities[officer.user];
              return {
                ...officer,
                user: {
                  ...user,
                  groups: get(user, 'groups', []),
                },
                sites: get(officer, 'sites', []),
                departments: get(officer, 'departments', []),
                professions: get(officer, 'professions', []),
              };
            }),
          ),
        ),
      ),
    );
  }

  getHospitalOfficerConfigurations(): Observable<IHospitalOfficerConfigurationFormState[]> {
    return this.getAllExtendedHospitalOfficers().pipe(
      map((officers) =>
        officers.map((officer) => ({
          ...officer,
          user: {
            ...officer.user,
            groups: box(officer.user.groups),
          },
          sites: box(officer.sites),
          departments: box(officer.departments),
          professions: box(officer.professions),
        })),
      ),
    );
  }

  getAllHospitalOfficersAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAllExtendedHospitalOfficers()),
    );
  }

  getAssigned() {
    return this.store.pipe(
      select(selectAssignedHospitalOfficer),
      filter((x) => !!x),
    );
  }

  getAssignedPreferredSpecialty() {
    return this.store.pipe(select(selectAssignedHospitalOfficerPreferredSpecialty));
  }

  getAssignedPreferredSpecialtyAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAssignedPreferredSpecialty()),
    );
  }

  getAllHospitalOfficerWithUsers(): Observable<IHospitalOfficerWithUser[]> {
    return combineLatest([this.getAll(), this.userService.getAll()]).pipe(
      map(
        ([hospitalOfficers, users]: [
          IHospitalOfficerEntity[],
          IUserEntity[],
        ]): IHospitalOfficerWithUser[] =>
          hospitalOfficers.map((hospitalOfficer) => {
            const userDetails = users.find((x) => x.id === hospitalOfficer.user);
            return { ...hospitalOfficer, user: userDetails };
          }),
      ),
    );
  }

  getHospitalOfficerPersonalDetails() {
    return this.store.pipe(select(selectHospitalOfficerPersonalDetails));
  }

  getHospitalOfficerInitials() {
    return this.store.pipe(select(selectHospitalOfficerUser)).pipe(
      map((personalDetails) => {
        if (personalDetails) {
          return (personalDetails.firstName + ' ' + personalDetails.lastName)
            .split(' ')
            .map((n) => n[0])
            .join('')
            .toUpperCase();
        }
        return '';
      }),
    );
  }

  getControlContactOfficer(): Observable<IHospitalOfficerPersonalDetailsEntity> {
    return combineLatest([
      this.store.pipe(select(selectAssignedHospitalControlContactOfficer)),
      this.userService.getAll(),
      this.getAll(),
    ]).pipe(
      map(([controlContactOfficer, users, hospitalOfficers]) => {
        const hospitalOfficer =
          controlContactOfficer > 0
            ? hospitalOfficers.find((officer) => officer.id === controlContactOfficer)
            : null;

        const user = hospitalOfficer ? users.find((u) => u.id === hospitalOfficer.user) : null;

        return getHospitalOfficerPersonalDetails(hospitalOfficer, user);
      }),
    );
  }

  loadByIds(ids: number[], loadUsers: boolean = false) {
    ids = uniq(ids).filter(isNumber);
    if (ids) {
      return this.fetch({ id: ids }, loadUsers);
    }
    return EMPTY;
  }

  fetch(query?: Query, loadUsers: boolean = false) {
    if (isString(query) || isNumber(query))
      return this.persistenceService.retrieve(query).pipe(
        mergeMap((entity) => {
          const actions: Observable<Action>[] = [of(new UpsertOneMessage({ entity }))];

          if (loadUsers) {
            actions.push(this.userService.loadOne([entity.user]));
          }

          return merge(...actions);
        }),
      );

    return this.persistenceService.retrieve(query).pipe(
      mergeMap(({ results }) => {
        const actions: Observable<Action>[] = [
          of(new UpsertMultipleMessage({ entities: results })),
        ];

        if (loadUsers) {
          const ids: number[] = [];
          for (const result of results) {
            ids.push(result.user);
          }
          actions.push(this.userService.loadMultiple(ids));
        }

        return merge(...actions);
      }),
    );
  }

  loadCurrent(loadUser?: boolean, form: boolean = false) {
    let loadAction$ = this.fetchCurrent(loadUser) as Observable<UpsertOneMessage>;
    loadAction$ = loadAction$.pipe(share());
    const actions$ = of(new this.loadingMessages.SetLoadingMessage({}));

    if (form) {
      return concat(
        actions$,
        loadAction$,
        this.postLoadHospitalOfficerForm(loadAction$),
        of(new this.loadingMessages.ResetLoadingMessage({})),
      );
    }

    return concat(actions$, loadAction$, of(new this.loadingMessages.ResetLoadingMessage({})));
  }

  postLoadHospitalOfficerForm(loadAction$: Observable<UpsertOneMessage>): Observable<Action> {
    return loadAction$.pipe(
      filter((action) => action.type === MessageTypes.UPSERT_ONE),
      map((action) => action.payload.entity),
      mergeMap((hospitalOfficerEntity) =>
        of(hospitalOfficerEntity).pipe(
          map(selectHospitalOfficerFormStateFromEntity()),
          map(
            (hospitalOfficerFormState) =>
              new InitializeHospitalOfficerFormMessage({ hospitalOfficerFormState }),
          ),
        ),
      ),
    );
  }

  fetchCurrent(loadUser?: boolean) {
    return this.persistenceService.retrieveCurrent<IHospitalOfficerEntity>().pipe(
      mergeMap((result) => {
        let currentId = null;

        if (result) {
          currentId = result.id;
        }

        const actions$ = from([
          new UpsertOneMessage({ entity: result }),
          new SetAssignedMessage({ id: currentId }),
          new preferredWardTemp.SetSelectUnassignedMessage({
            selectUnassigned: result.prefersUnassignedWards,
          }),
          new SetSelectUnassignedMessage({ selectUnassigned: result.prefersUnassignedWards }),
        ]);

        if (loadUser) {
          return merge(actions$, this.userService.loadOne(result.user).pipe(toSessionUser()));
        }
        return actions$;
      }),
    );
  }

  public hasExtendedHoursAccessOnly(site$: Observable<number>) {
    return combineLatest([
      this.permissionService.hasCurrentPermission(
        'publish_extended_hours_job_listing_for_hospital_sites',
      ),
      this.hospitalOfficerSiteService.getAllSitesIds(),
      site$,
    ]).pipe(
      map(([hasPermission, assignedSites, site]: [boolean, number[], number]): boolean => {
        if (
          hasPermission &&
          assignedSites &&
          isNumber(site) &&
          !isNaN(site) &&
          assignedSites.indexOf(site) < 0
        ) {
          return true;
        }

        return false;
      }),
    );
  }
  private processExportData(data: IExtendedHospitalOfficerEntity[]) {
    return this.userService.createUpsertMultipleMessage(data.map((entity) => entity.user));
  }
}
