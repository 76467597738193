import { AuthGroupMessageTypes as MessageTypes, AuthGroupMessages } from '././auth-group.messages';
import { IAuthGroupEntityState } from './interfaces';
import { adapter, initialState, paginationAdapter } from './auth-group.adapter';
import { combineReducers } from '@ngrx/store';
import { IAuthGroupEntity } from '../../interfaces/api/auth-group-entity';
import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';

export * from './interfaces';

export const paginationReducer = paginationAdapter.createReducer();

export type IAuthGroupPaginatedEntityState = IAuthGroupEntityState &
  IPaginatedEntityState<IAuthGroupEntity>;

export function entityReducer(
  state = initialState,
  action: AuthGroupMessages
): IAuthGroupEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export function paginatedEntityStateReducer(
  state: IAuthGroupPaginatedEntityState,
  action: AuthGroupMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export function reducer(state, action) {
  return combineReducers({
    entityState: paginatedEntityStateReducer,
  })(state, action);
}
