import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PaginatedStateService } from '@locumsnest/core/src';
import { IMicroAppConfiguration } from '@locumsnest/core/src/lib/micro-app/interfaces';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { PRODUCT_CODES } from '../../core/constants';
import { IAuthGroupEntity } from '../../interfaces/api/auth-group-entity';
import { AUTH_GROUPS } from '../constants';
import {
  AuthGroupPaginationMessages,
  ResetAuthGroupPaginationMessage,
  UpsertAuthGroupPageMessage,
  UpsertMultipleMessage,
} from './auth-group.messages';
import { AuthGroupPersistenceService } from './auth-group.persistence.service';
import {
  authGroupPaginationSelectors,
  selectAllAuthGroups,
  selectAuthGroupEntityState,
  selectAuthGroupOptions,
} from './auth-group.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGroupService extends PaginatedStateService<
  IAuthGroupEntity,
  UpsertAuthGroupPageMessage,
  ResetAuthGroupPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: AuthGroupPersistenceService,
    protected microAppService: MicroAppService,
  ) {
    super();
  }

  get paginationMessages() {
    return AuthGroupPaginationMessages;
  }

  get paginationSelectors() {
    return authGroupPaginationSelectors;
  }

  get entityStateSelector() {
    return selectAuthGroupEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  getAll() {
    return this.store.pipe(select(selectAllAuthGroups));
  }

  getAllMy() {
    return this.getAllAfterLoading('myGroups');
  }

  getAuthGroupOptions() {
    return this.store.pipe(
      select(selectAuthGroupOptions),
      filter((options) => options && options.length > 0),
    );
  }

  fetch() {
    return this.loadAllPages('default', null);
  }

  fetchMy() {
    return this.loadAllPages('myGroups', {
      controllerResource: 'authGroupCurrent',
      skipSerializer: true,
    });
  }

  getOfficerBelongsToAuthGroup(authGroup: string): Observable<boolean> {
    return this.getGroupNames().pipe(map((groupNames) => groupNames.includes(authGroup)));
  }

  getOfficerBelongsToAuthGroups(authGroup: string[]): Observable<boolean> {
    return this.getGroupNames().pipe(
      map((groupNames) => authGroup.every((group) => groupNames.includes(group))),
    );
  }

  getOfficerBelongsToAnyOfTheAuthGroups(authGroup: string[]): Observable<boolean> {
    return this.getGroupNames().pipe(
      map((groupNames) => authGroup.some((group) => groupNames.includes(group))),
    );
  }

  getGroupNames(): Observable<string[]> {
    return this.getAllMy().pipe(map((groups) => groups.map((group) => group.name)));
  }

  getCurrentGroupMicroAppConfiguration(): Observable<IMicroAppConfiguration | null> {
    return this.getAllMy().pipe(
      map((groups) => {
        const groupNames = groups.map((group) => group.name);

        if (
          groupNames.includes(AUTH_GROUPS.HOSPITAL_OFFICER) ||
          groupNames.includes(AUTH_GROUPS.RESTRICTED_HOSPITAL_OFFICER)
        ) {
          return this.microAppService.getMicroAppByCode(PRODUCT_CODES.MATCH);
        }

        if (
          groupNames.includes(AUTH_GROUPS.STAFFBANK_MANAGER) ||
          groupNames.includes(AUTH_GROUPS.RESTRICTED_STAFFBANK_MANAGER) ||
          groupNames.includes(AUTH_GROUPS.PAYROLL_MANAGER)
        ) {
          return this.microAppService.getMicroAppByCode(PRODUCT_CODES.PASSPORT_PLUS);
        }

        if (groupNames.includes(AUTH_GROUPS.COMMUNITY_MANAGER)) {
          return this.microAppService.getMicroAppByCode(PRODUCT_CODES.COMMUNITY);
        }

        if (groupNames.includes(AUTH_GROUPS.LINK_SHIFT_INTEGRATION_MANAGER)) {
          return this.microAppService.getMicroAppByCode(PRODUCT_CODES.LINK);
        }
        if (groupNames.includes(AUTH_GROUPS.EXTERNAL_STAFFING_PROVIDER)) {
          return this.microAppService.getMicroAppByCode(PRODUCT_CODES.AGENCY);
        }
        if (groupNames.includes(AUTH_GROUPS.TABLEAU_OFFICER)) {
          return this.microAppService.getMicroAppByCode(PRODUCT_CODES.INTELLIGENCE);
        }

        if (groupNames.includes(AUTH_GROUPS.EXTERNAL_STAFFING_PROVIDER)) {
          return this.microAppService.getMicroAppByCode(PRODUCT_CODES.AGENCY);
        }

        return null;
      }),
    );
  }
}
