import { createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { FormGroupState } from 'ngrx-forms';

import {
  IHospitalOfficerEntity,
  IHospitalOfficerUpdateEntity,
} from '../../../interfaces/api/hospital-officer-entity';
import { selectHospitalOfficerState } from '../hospital-officer.selectors';
import { State } from '../interfaces';
import { IHospitalOfficerFormState } from '../interfaces/hospital-officer-form-state';
import { alertStateAdapter } from './form.adapter';

const selectors = alertStateAdapter.getSelectors();
export const getFormState = (state: State) => state.formState;

export const getHospitalOfficerFormStateFromEntity = (
  hospitalOfficerEntity: IHospitalOfficerEntity
): IHospitalOfficerFormState => {
  const {
    id,
    email,
    telephone,
    activityDigestEmailFrequency,
    receiveStaffbankRundownEmail,
    shiftsLockedDigestEmailFrequency,
    timeSheetsLockedDigestEmailFrequency,
  } = cloneDeep(hospitalOfficerEntity);

  const hospitalOfficerForm: IHospitalOfficerFormState = {
    id,
    email,
    telephone,
    activityDigestEmailFrequency,
    receiveStaffbankRundownEmail,
    shiftsLockedDigestEmailFrequency,
    timeSheetsLockedDigestEmailFrequency,
  };

  return hospitalOfficerForm;
};

export const getEntityStateFromFormState = (
  formState: FormGroupState<IHospitalOfficerFormState>
): IHospitalOfficerUpdateEntity => {
  const {
    id,
    email,
    telephone,
    activityDigestEmailFrequency,
    receiveStaffbankRundownEmail,
    shiftsLockedDigestEmailFrequency,
    timeSheetsLockedDigestEmailFrequency,
  } = cloneDeep(formState.value);

  const hospitalOfficerForm: IHospitalOfficerUpdateEntity = {
    id,
    email,
    telephone,
    activityDigestEmailFrequency,
    receiveStaffbankRundownEmail,
    shiftsLockedDigestEmailFrequency,
    timeSheetsLockedDigestEmailFrequency,
  };

  return hospitalOfficerForm;
};

export const selectHospitalOfficerFormState = createSelector(
  selectHospitalOfficerState,
  getFormState
);

export const selectHospitalOfficerAlertToDisplay = createSelector(
  selectHospitalOfficerFormState,
  selectors.selectAlertState
);

export const selectHospitalOfficerFormStateFromEntity = () => getHospitalOfficerFormStateFromEntity;
