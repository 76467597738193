import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { StateService } from '@locumsnest/core';

import { IHospitalOfficerSiteEntity } from '../../interfaces/api/hospital-officer-site-entity';
import { SetCollectionMessage } from './hospital-officer-site.messages';
import { HospitalOfficerSitePersistenceService } from './hospital-officer-site.persistence.service';
import {
  selectAllHospitalOfficerSites,
  selectAllHospitalOfficerSitesIds,
} from './hospital-officer-site.selectors';

@Injectable({
  providedIn: 'root',
})
export class HospitalOfficerSiteService extends StateService<IHospitalOfficerSiteEntity> {
  constructor(
    private store: Store,
    protected persistenceService: HospitalOfficerSitePersistenceService
  ) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllHospitalOfficerSites));
  }

  getAllSitesIds() {
    return this.store.pipe(select(selectAllHospitalOfficerSitesIds));
  }

  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }
}
