import { Action, combineReducers } from '@ngrx/store';

import { ILoadingState } from '@locumsnest/core/src/lib/adapters/loading-state-adapter/interfaces';
import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';

import { IHospitalOfficerEntity } from '../../interfaces/api/hospital-officer-entity';
import {
  adapter,
  initialState,
  loadingAdapter,
  paginationAdapter,
} from './hospital-officer.adapter';
import {
  HospitalOfficerMessages,
  HospitalOfficerMessageTypes as MessageTypes,
} from './hospital-officer.messages';
import { IHospitalOfficerEntityState } from './interfaces';

export * from './interfaces';

export const featureKey = 'hospitalOfficers';

export const paginationReducer = paginationAdapter.createReducer();
export const loadingReducer = loadingAdapter.createReducer();
export function loadingStateReducer(s: ILoadingState, a: Action) {
  return loadingReducer(s, a);
}

export type IHospitalOfficerPaginatedEntityState = IHospitalOfficerEntityState &
  IPaginatedEntityState<IHospitalOfficerEntity>;

export function entityReducer(
  state = initialState,
  action: HospitalOfficerMessages
): IHospitalOfficerEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.SET_ASSIGNED: {
      return {
        ...state,
        assignedHospitalOfficerId: action.payload.id,
      };
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export function paginatedEntityStateReducer(
  state: IHospitalOfficerPaginatedEntityState,
  action: HospitalOfficerMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export function reducer(state, action) {
  return combineReducers({
    entityLoadingState: loadingStateReducer,
    entityState: paginatedEntityStateReducer,
  })(state, action);
}
