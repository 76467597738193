import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll } from './hospital-officer-site.reducer';
import {
  IHospitalOfficerSiteEntityState,
  IHospitalOfficerSiteFeatureState,
  IHospitalOfficerSiteSharedState,
} from './interfaces';

export const getEntityState = (state: { entityState: IHospitalOfficerSiteEntityState }) =>
  state.entityState;

export const getHospitalOfficerSitesIds = (state: IHospitalOfficerSiteEntityState) =>
  (state.ids as number[]).map((id: number) => state.entities[id].site);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectHospitalOfficerSiteState =
  createFeatureSelector<IHospitalOfficerSiteFeatureState & IHospitalOfficerSiteSharedState>(
    'hospitalOfficerSites'
  );

export const selectHospitalOfficerSiteEntityState = createSelector(
  selectHospitalOfficerSiteState,
  getEntityState
);

export const selectAllHospitalOfficerSites = createSelector(
  selectHospitalOfficerSiteEntityState,
  selectAll
);

export const selectAllHospitalOfficerSitesIds = createSelector(
  selectHospitalOfficerSiteEntityState,
  getHospitalOfficerSitesIds
);
