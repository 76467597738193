import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isNumber, isString } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Query, StateService } from '@locumsnest/core/src';

import { IUserEntity } from '../../interfaces/api/user-entity';
import { UpsertMultipleMessage, UpsertOneMessage } from './user.messages';
import { UserPersistenceService } from './user.persistence.service';
import { selectUser, selectUserEntities } from './user.selectors';
import { selectAllUsers } from './user.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserService extends StateService<IUserEntity> {
  constructor(private store: Store, protected persistenceService: UserPersistenceService) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllUsers));
  }

  getOne(id: number) {
    return this.store.pipe(select(selectUser(id)));
  }

  fetch(query?: Query) {
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((user) => new UpsertOneMessage({ user })));

    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ users: results })));
  }

  loadOne(id) {
    const loadAction$ = this.fetch(id) as Observable<UpsertOneMessage>;
    return loadAction$;
  }

  loadMultiple(ids: number[]) {
    const loadAction$ = this.fetch({ id: ids }) as Observable<UpsertOneMessage>;
    return loadAction$;
  }

  createUpsertMultipleMessage(users) {
    return new UpsertMultipleMessage({ users });
  }

  getEntities() {
    return this.store.pipe(select(selectUserEntities));
  }
}
