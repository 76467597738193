import { IHospitalOfficerSiteEntity } from './../../interfaces/api/hospital-officer-site-entity';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';

export const adapter: EntityAdapter<IHospitalOfficerSiteEntity> =
  createEntityAdapter<IHospitalOfficerSiteEntity>({});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedHospitalOfficerSiteId: null,
});
