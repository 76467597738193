import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IHospitalOfficerSiteEntity } from '../../interfaces/api/hospital-officer-site-entity';

export enum HospitalOfficerSiteSignalTypes {
  LOAD_JOB_LISTINGS = '[HospitalOfficerSite][Signal] Load Collection',
}
export enum HospitalOfficerSiteMessageTypes {
  SET_COLLECTION = '[HospitalOfficerSite][Message] Set Collection',
  ADD_ONE = '[HospitalOfficerSite][Message] Add One',
  UPDATE_ONE = '[HospitalOfficerSite][Message] Update One',
  UPSERT_ONE = '[HospitalOfficerSite][Message] Upsert One',
  DELETE_ONE = '[HospitalOfficerSite][Message] Delete One',
  ADD_MULTIPLE = '[HospitalOfficerSite][Message] Add All',
  DELETE_MULTIPLE = '[HospitalOfficerSite][Message] Delete Many',
  UPSERT_MULTIPLE = '[HospitalOfficerSite][Message] Upsert Many',
  UPDATE_MULTIPLE = '[HospitalOfficerSite][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = HospitalOfficerSiteMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IHospitalOfficerSiteEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = HospitalOfficerSiteMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IHospitalOfficerSiteEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = HospitalOfficerSiteMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IHospitalOfficerSiteEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = HospitalOfficerSiteMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IHospitalOfficerSiteEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = HospitalOfficerSiteMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IHospitalOfficerSiteEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = HospitalOfficerSiteMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IHospitalOfficerSiteEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = HospitalOfficerSiteMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IHospitalOfficerSiteEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = HospitalOfficerSiteMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = HospitalOfficerSiteMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type HospitalOfficerSiteMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
