import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAuthGroupEntity } from '../../interfaces/api/auth-group-entity';
import { loadingAdapter, paginationAdapter } from './auth-group.adapter';
import { selectAll } from './auth-group.reducer';
import { IAuthGroupEntityState, IAuthGroupFeatureState, IAuthGroupSharedState } from './interfaces';

export const getEntityState = (state: { entityState: IAuthGroupEntityState }) => state.entityState;

export const getAuthGroupOptions = (authGroups: IAuthGroupEntity[]): ISelectOption[] =>
  authGroups.map((authGroup) => ({ id: authGroup.id, name: authGroup.name }));

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectAuthGroupState =
  createFeatureSelector<IAuthGroupFeatureState & IAuthGroupSharedState>('authGroups');
export const selectAuthGroupEntityState = createSelector(selectAuthGroupState, getEntityState);

export const selectAllAuthGroups = createSelector(selectAuthGroupEntityState, selectAll);

export const selectAuthGroupOptions = createSelector(selectAllAuthGroups, getAuthGroupOptions);

export const authGroupPaginationSelectors = paginationAdapter.paginationSelectors(
  selectAuthGroupState,
  loadingAdapter,
  'entityState.pagination',
  'entityState',
  false
);
