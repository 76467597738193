import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { catchError, EMPTY, map } from 'rxjs';

import { isEphemeralOrigin } from '../../core/constants';
import { SetHealthCheckMessage } from './health-check.messages';
import { HealthCheckPersistenceService } from './health-check.persistence.service';
import { selectNextShutDown } from './health-check.selectors';

@Injectable({
  providedIn: 'root',
})
export class HealthCheckService {
  constructor(private store: Store, protected persistenceService: HealthCheckPersistenceService) {}

  fetch() {
    return this.persistenceService.retrieve().pipe(
      map((healthCheck) => new SetHealthCheckMessage({ healthCheck })),
      catchError(() => {
        if (isEphemeralOrigin()) {
          // this.router.navigate(['boot']);
        }
        // health check is used to bring some extra info
        // fail silently since not critical
        return EMPTY;
      })
    );
  }

  getNextShutDown() {
    return this.store.pipe(select(selectNextShutDown));
  }
}
