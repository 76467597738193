import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IUserFeatureState, IUserSharedState } from './interfaces';
import { selectAll, selectEntities } from './user.reducer';

export const selectUserState = createFeatureSelector<IUserFeatureState & IUserSharedState>('users');
export const selectUserEntityState = createSelector(selectUserState, (state) => state.entityState);

export const selectAllUsers = createSelector(selectUserEntityState, selectAll);

export const selectUserEntities = createSelector(selectUserEntityState, selectEntities);

export const selectUser = (id: number) =>
  createSelector(selectAllUsers, (users) => users.find((user) => user.id === id));
