import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHealthCheckEntity } from '../../interfaces/api/health-check-entity';

@Injectable({
  providedIn: 'root',
})
export class HealthCheckPersistenceService {
  constructor(private http: HttpClient) {}

  retrieve() {
    return this.http.get<IHealthCheckEntity>('/health/');
  }
}
