import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IHospitalOfficerEntity } from '../../interfaces/api/hospital-officer-entity';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export function sortById(a: IHospitalOfficerEntity, b: IHospitalOfficerEntity): number {
  return a.id - b.id;
}

export const adapter: EntityAdapter<IHospitalOfficerEntity> =
  createEntityAdapter<IHospitalOfficerEntity>({
    sortComparer: sortById,
  });

export const messageableFactory =
  MessageableFactory.forFeature<'HospitalOfficer'>('HospitalOfficer');
export const signalableFactory = SignalableFactory.forFeature<'HospitalOfficer'>('HospitalOfficer');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'HospitalOfficer'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedHospitalOfficerId: null,
  assignedHospitalOfficerId: null,
});
