import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  IHospitalOfficerEntity,
  IHospitalOfficerPersonalDetailsEntity,
} from '../../interfaces/api/hospital-officer-entity';
import { IUserEntity } from '../../interfaces/api/user-entity';
import { selectAllUsers } from '../../user/+state/user.selectors';
import { loadingAdapter, paginationAdapter } from './hospital-officer.adapter';
import { featureKey, selectAll } from './hospital-officer.reducer';
import {
  IHospitalOfficerEntityState,
  IHospitalOfficerFeatureState,
  IHospitalOfficerSharedState,
} from './interfaces';

export const getEntityState = (state: { entityState: IHospitalOfficerEntityState }) => {
  if (!state) return null;
  return state.entityState;
};

export const getAssignedId = (state: IHospitalOfficerEntityState) =>
  state.assignedHospitalOfficerId;
export const getAssignedEntity = (state: IHospitalOfficerEntityState) => {
  if (!state || state.assignedHospitalOfficerId === null) return null;

  return state.entities[state.assignedHospitalOfficerId];
};

export const getPreferredSpecialty = (entity: IHospitalOfficerEntity) =>
  entity ? entity.preferredSpecialty : null;

export const getAssignedHospitalOfficerUserId = (entity: IHospitalOfficerEntity): number =>
  entity ? entity.user : null;

export const getHospitalOfficerPersonalDetails = (
  hospitalOfficer: IHospitalOfficerEntity,
  user: IUserEntity,
): IHospitalOfficerPersonalDetailsEntity => {
  let hospitalPersonalDetails: IHospitalOfficerPersonalDetailsEntity;

  if (user && hospitalOfficer) {
    hospitalPersonalDetails = {
      id: hospitalOfficer.id,
      firstName: user.firstName,
      lastName: user.lastName,
      jobTitle: hospitalOfficer.jobTitle,
      email: user.email,
      telephone: hospitalOfficer.telephone,
      activityDigestEmailFrequency: hospitalOfficer.activityDigestEmailFrequency,
      receiveStaffbankRundownEmail: hospitalOfficer.receiveStaffbankRundownEmail,
      shiftsLockedDigestEmailFrequency: hospitalOfficer.shiftsLockedDigestEmailFrequency,
      timeSheetsLockedDigestEmailFrequency: hospitalOfficer.timeSheetsLockedDigestEmailFrequency,
      profileImage: hospitalOfficer.profileImage,
    };
  }

  return hospitalPersonalDetails;
};

export const selectHospitalOfficerState = createFeatureSelector<
  IHospitalOfficerFeatureState & IHospitalOfficerSharedState
>(featureKey);

export const selectHospitalOfficerEntityState = createSelector(
  selectHospitalOfficerState,
  getEntityState,
);

export const selectAllHospitalOfficers = createSelector(
  selectHospitalOfficerEntityState,
  selectAll,
);

export const selectAssignedHospitalOfficer = createSelector(
  selectHospitalOfficerEntityState,
  getAssignedEntity,
);

export const selectAssignedHospitalOfficerPreferredSpecialty = createSelector(
  selectAssignedHospitalOfficer,
  getPreferredSpecialty,
);

export const selectHospitalOfficerUserId = createSelector(
  selectAssignedHospitalOfficer,
  getAssignedHospitalOfficerUserId,
);

export const selectHospitalOfficerUser = createSelector(
  selectHospitalOfficerUserId,
  selectAllUsers,
  (userId, users) => users.find((user) => user.id === userId),
);

export const selectHospitalOfficerPersonalDetails = createSelector(
  selectAssignedHospitalOfficer,
  selectHospitalOfficerUser,
  (hospitalOfficer, user) => getHospitalOfficerPersonalDetails(hospitalOfficer, user),
);

export const hospitalOfficerPaginationSelectors = paginationAdapter.paginationSelectors(
  selectHospitalOfficerEntityState,
  loadingAdapter,
);
export const loadingStateSelectors = loadingAdapter.getSelectors(selectHospitalOfficerState);
