import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { IHospitalOfficerEntity } from '../../interfaces/api/hospital-officer-entity';
import { paginationAdapter } from './hospital-officer.adapter';

export const HospitalOfficerPaginationMessages = paginationAdapter.getMessages();

export class UpsertHospitalOfficerPageMessage extends HospitalOfficerPaginationMessages.UpsertPageMessage {}
export class UpsertHospitalOfficerMultiplePagesMessage extends HospitalOfficerPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetHospitalOfficerPaginationMessage extends HospitalOfficerPaginationMessages.ResetPaginationMessage {}

export enum HospitalOfficerSignalTypes {
  LOAD_HOSPITAL_OFFICERS = '[HospitalOfficer][Signal] Load Collection',
}
export enum HospitalOfficerMessageTypes {
  SET_COLLECTION = '[HospitalOfficer][Message] Set Collection',
  ADD_ONE = '[HospitalOfficer][Message] Add One',
  UPDATE_ONE = '[HospitalOfficer][Message] Update One',
  UPSERT_ONE = '[HospitalOfficer][Message] Upsert One',
  DELETE_ONE = '[HospitalOfficer][Message] Delete One',
  ADD_MULTIPLE = '[HospitalOfficer][Message] Add All',
  DELETE_MULTIPLE = '[HospitalOfficer][Message] Delete Many',
  UPSERT_MULTIPLE = '[HospitalOfficer][Message] Upsert Many',
  UPDATE_MULTIPLE = '[HospitalOfficer][Message] Update Many',
  SET_ASSIGNED = '[HospitalOfficer][Message] Set Assigned',
}
export class SetCollectionMessage implements Action {
  readonly type = HospitalOfficerMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IHospitalOfficerEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = HospitalOfficerMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IHospitalOfficerEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = HospitalOfficerMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IHospitalOfficerEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = HospitalOfficerMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IHospitalOfficerEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = HospitalOfficerMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IHospitalOfficerEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = HospitalOfficerMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IHospitalOfficerEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = HospitalOfficerMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IHospitalOfficerEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = HospitalOfficerMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = HospitalOfficerMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: number[] }) {}
}

export class SetAssignedMessage implements Action {
  readonly type = HospitalOfficerMessageTypes.SET_ASSIGNED;

  constructor(public payload: { id: number }) {}
}

export type HospitalOfficerMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | SetAssignedMessage;
