import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IAuthGroupEntity } from '../../interfaces/api/auth-group-entity';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export function sortByName(a: IAuthGroupEntity, b: IAuthGroupEntity): number {
  return a.id - b.id;
}

export const adapter: EntityAdapter<IAuthGroupEntity> = createEntityAdapter<IAuthGroupEntity>({
  sortComparer: sortByName,
});

export const messageableFactory = MessageableFactory.forFeature<'AuthGroup'>('AuthGroup');
export const signalableFactory = SignalableFactory.forFeature<'AuthGroup'>('AuthGroup');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'AuthGroup'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedAuthGroupId: null,
});
