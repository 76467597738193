import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IAuthGroupEntity } from '../../interfaces/api/auth-group-entity';

@Injectable({
  providedIn: 'root',
})
export class AuthGroupPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IAuthGroupEntity
> {
  protected readonly endpoint = 'authGroup';
}
